let button = document.querySelector('.menu-icon__cheeckbox')
let container = document.querySelector('#container')
let menu = document.querySelector('#mobile-menu')
let elements = document.querySelectorAll('#header-menu-elements')
let menuIconActive = document.querySelector('#menu-icon-active')

for (let i = 0; i < elements.length; i+=1) { 
    elements[i].addEventListener('click', (e) => {
        setTimeout(() => {
            menu.classList.remove('menu_active') 
        container.classList.remove('active')
        menuIconActive.classList.remove('menu-icon-active')
        }, 200)

    })
}

button.addEventListener('click', (e) => {
    container.classList.toggle('active')
    menu.classList.toggle('menu_active')
    menuIconActive.classList.toggle('menu-icon-active')
})


